<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    style="background-color: #ffffff"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">List Bill Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.bills.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('timestamp')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('billno')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Receipt No
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('cashier')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Cashier</div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('bnotes')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Booking Notes
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Order No</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('discount')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Discount</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('item')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Item</div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('quantity')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Quantity</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('rate')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Rate</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('tax')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Tax</div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('total')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Total</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="listBillReportList.length"
                        :set="
                          ((totalProductPrice = 0),
                          (grandTotal = 0),
                          (grandTotalTax = 0),
                          (GrandTotalQuantity = 0))
                        "
                      >
                        <template
                          v-for="(invoice, index) in listBillReportList"
                        >
                          <template v-if="invoice.order_details.length > 0">
                            <tr :key="`invoice_${index}`">
                              <td
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('timestamp')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    invoice.invoice_created_datetime | timeStamp
                                  }}
                                </div>
                              </td>
                              <td
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('billno')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ invoice.receipt_no }}
                                </div>
                              </td>
                              <td
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('cashier')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ invoice.cashier }}
                                </div>
                              </td>
                              <td></td>
                              <td
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('discount')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    invoice.promotion_name
                                      ? invoice.promotion_name + " promotion"
                                      : invoice.membership_name
                                      ? invoice.membership_name + " membership"
                                      : invoice.discount_applied == true
                                      ? "Special Discount"
                                      : "NA"
                                  }}
                                </div>
                              </td>
                            </tr>
                            <template
                              v-for="(order, oIndex) in invoice.order_details"
                            >
                              <tr :key="`order_${index}_${oIndex}`">
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('timestamp')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('billno')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('cashier')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('bnotes')"
                                ></td>

                                <td :colspan="1">{{ order.invoice_seq_no }}</td>
                                <td
                                  class="md-table-cell"
                                  v-if="checkTableColumnVisibility('discount')"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{
                                      order.promotion_name
                                        ? order.promotion_name + " promotion"
                                        : order.membership_name
                                        ? order.membership_name + " membership"
                                        : "NA"
                                    }}
                                  </div>
                                </td>
                              </tr>
                              <tr
                                :key="`item_${index}_${oIndex}_${oiIndex}`"
                                v-for="(item, oiIndex) in order.items"
                              >
                                <template>
                                  <td
                                    :colspan="1"
                                    v-if="
                                      checkTableColumnVisibility('timestamp')
                                    "
                                  ></td>
                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('billno')"
                                  ></td>
                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('cashier')"
                                  ></td>
                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('bnotes')"
                                  ></td>
                                  <td
                                    :colspan="1"
                                    v-if="
                                      checkTableColumnVisibility('discount')
                                    "
                                  ></td>
                                  <td :colspan="1"></td>
                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('item')"
                                    class="md-table-cell-container text-center"
                                  >
                                    {{ item.product_name }}
                                  </td>
                                  <td
                                    :colspan="1"
                                    v-if="
                                      checkTableColumnVisibility('quantity')
                                    "
                                    class="md-table-cell-container text-center"
                                    :set="(GrandTotalQuantity += item.quantity)"
                                  >
                                    {{
                                      Math.abs(item.quantity) | numberFormatter
                                    }}
                                  </td>

                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('rate')"
                                    class="md-table-cell-container text-center"
                                    :set="
                                      (totalProductPrice += item.product_price)
                                    "
                                  >
                                    {{
                                      Math.abs(item.product_price) | toCurrency
                                    }}
                                  </td>

                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('tax')"
                                    class="md-table-cell-container text-center"
                                    :set="(grandTotalTax += Math.abs(item.tax))"
                                  >
                                    {{ Math.abs(item.tax) | toCurrency }}
                                  </td>

                                  <td
                                    :colspan="1"
                                    v-if="checkTableColumnVisibility('total')"
                                    class="md-table-cell-container text-center"
                                    :set="(grandTotal += Math.abs(item.total))"
                                  >
                                    {{ Math.abs(item.total) | toCurrency }}
                                  </td>
                                </template>
                              </tr>
                              <tr
                                class="md-table-row"
                                :key="`item_total_${index}_${oIndex}`"
                              >
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('timestamp')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('billno')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('cashier')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('discount')"
                                ></td>
                                <td :colspan="1" class="total_td_bg"></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('item')"
                                  class="md-table-cell-container text-center total_td_bg"
                                >
                                  <strong>Total</strong>
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('bnotes')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('quantity')"
                                  class="md-table-cell-container text-center total_td_bg"
                                >
                                  {{
                                    Math.abs(
                                      getTotal(
                                        listBillReportList[index].order_details[
                                          oIndex
                                        ].items,
                                        "quantity"
                                      )
                                    ) | numberFormatter
                                  }}
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('rate')"
                                  class="md-table-cell-container text-center total_td_bg"
                                >
                                  {{
                                    Math.abs(
                                      getTotal(
                                        listBillReportList[index].order_details[
                                          oIndex
                                        ].items,
                                        "product_price"
                                      )
                                    ) | toCurrency
                                  }}
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('tax')"
                                  class="md-table-cell-container text-center total_td_bg"
                                >
                                  {{
                                    Math.abs(
                                      getTotal(
                                        listBillReportList[index].order_details[
                                          oIndex
                                        ].items,
                                        "tax"
                                      )
                                    ) | toCurrency
                                  }}
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('total')"
                                  class="md-table-cell-container text-center total_td_bg"
                                >
                                  {{
                                    Math.abs(
                                      getTotal(
                                        listBillReportList[index].order_details[
                                          oIndex
                                        ].items,
                                        "total"
                                      )
                                    ) | toCurrency
                                  }}
                                </td>
                              </tr>
                            </template>
                            <template>
                              <tr
                                class="md-table-row grand_total_bg"
                                :key="`grand_total_${index}`"
                              >
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('billno')"
                                ></td>
                                <td
                                  :colspan="1"
                                  class="md-table-cell-container text-center"
                                  v-if="checkTableColumnVisibility('timestamp')"
                                >
                                  <strong>TOTAL</strong>
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('cashier')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('discount')"
                                ></td>
                                <td :colspan="1"></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('item')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('bnotes')"
                                ></td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('quantity')"
                                  class="md-table-cell-container text-center"
                                >
                                  {{ Math.abs(GrandTotalQuantity) }}
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('rate')"
                                  class="md-table-cell-container text-center"
                                >
                                  {{ Math.abs(totalProductPrice) | toCurrency }}
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('tax')"
                                  class="md-table-cell-container text-center"
                                >
                                  {{ Math.abs(grandTotalTax) | toCurrency }}
                                </td>
                                <td
                                  :colspan="1"
                                  v-if="checkTableColumnVisibility('total')"
                                  class="md-table-cell-container text-center"
                                >
                                  {{ Math.abs(grandTotal) | toCurrency }}
                                </td>
                                <span
                                  :set="
                                    ((totalProductPrice = 0),
                                    (grandTotal = 0),
                                    (grandTotalTax = 0),
                                    (GrandTotalQuantity = 0))
                                  "
                                >
                                </span>
                              </tr>
                            </template>
                          </template>
                        </template>
                        <template>
                          <tr
                            class="md-table-row grand_total_bg font-weight-black sticky_bottom"
                            :key="`grand_total`"
                          >
                            <td class="md-table-cell" colspan="6">
                              <div class="md-table-cell-container text-center">
                                <strong> GRAND TOTAL</strong>
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{
                                    Math.abs(totalQuantity) | numberFormatter
                                  }}</strong
                                >
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{
                                    Math.abs(totalPrice) | toCurrency
                                  }}</strong
                                >
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{ Math.abs(totalTax) | toCurrency }}</strong
                                >
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{
                                    Math.abs(totalAmount) | toCurrency
                                  }}</strong
                                >
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  <v-pagination
                    v-model="page"
                    :length="totalPages"
                    class="new-pagination"
                    total-visible="7"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: { BackButton, ExportIcon, SvgIcon },
  data() {
    return {
      listBillReportList: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      columnData: [
        { name: "Timestamp", value: "timestamp" },
        { name: "Bill No", value: "billno" },
        { name: "Cashier", value: "cashier" },
        { name: "Item", value: "item" },
        { name: "Booking Notes", value: "bnotes" },
        { name: "Quantity", value: "quantity" },
        { name: "Rate", value: "rate" },
        { name: "Tax", value: "tax" },
        { name: "Discount", value: "discount" },
        { name: "Total", value: "total" },
      ],
      columns: [
        "timestamp",
        "billno",
        "cashier",
        "item",
        "pax",
        "quantity",
        "rate",
        "tax",
        "discount",
        "total",
      ],
      page: 1,
      totalPages: 1,
      totalAmount: 0,
      totalPrice: 0,
      totalTax: 0,
      totalQuantity: 0,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    getTotal(items, field) {
      // console.log(JSON.parser(items));
      // console.log(field);
      let sum = 0;
      items.map((x) => (sum += x[field]));
      return sum;
    },
    getGrandTotal(field) {
      let sum = 0;
      this.listBillReportList.forEach((products) => {
        products["items"].map((x) => (sum += x[field]));
      });
      return sum;
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }
      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "List-Bill-Report-" + this.fileName;
      else this.fileName = "List-Bill-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.totalAmount = 0;
      this.totalTax = 0;
      this.totalQuantity = 0;
      this.totalPrice = 0;
      this.$http
        .get(`venues/reports/list-bill-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.listBillReportList = data.data;
            this.totalPages = data.total_pages;
            this.hideLoader();
            this.listBillReportList.forEach((el) => {
              //  console.log(JSON.stringify(this.listBillReportList));
              //console.log(el.status_id);
              if (el.status_id != 13) {
                console.log(el);
                el.order_details.forEach((elOrders) => {
                  this.totalAmount += elOrders.total;
                  this.totalTax += elOrders.tax;
                  this.totalPrice += elOrders.price;
                  elOrders.items.forEach((elItems) => {
                    this.totalQuantity += elItems.quantity;
                  });
                });
              }
              // console.log(this.totalPrice);
            });
          }
          // console.log("totalTax");
          // console.log(this.totalTax);
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "list-bill-report-pdf";
      } else if (type == "excel") {
        link = "list-bill-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },

    getTotalColspan() {
      let colspan = 0;
      if (this.checkTableColumnVisibility("timestamp")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("billno")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("cashier")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("category")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("item")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("bnotes")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("pnotes")) {
        colspan += 1;
      }
      return colspan;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px 4px;
  border-radius: 2px;
  background-color: #edf7f8; /* Set color opacity to 10% */
  z-index: 1;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.total_td_bg {
  background: #edf9ff;
}
</style>
